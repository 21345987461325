<template>
	<div id="task_manage_add">
		<el-dialog title="编辑任务" v-model="pageConf.show" destroy-on-close :width="1200">
			<el-form :model="forms.datas" :rules="forms.rules" ref="formRef">
				<el-row class="task-body" :gutter="24">
					<el-col class="left-body" :span="14">
						<el-form-item prop="title">
							<div>任务信息</div>
							<el-input v-model="forms.datas.title" class="form-input" placeholder="请输入任务名称">
							</el-input>
						</el-form-item>
						<el-form-item prop="content">
							<TinymceEditor :content="forms.datas.content" :placeholder="pageConf.editor_placeholder"
								@editorContent="editorContent">
							</TinymceEditor>
						</el-form-item>
						<el-form-item v-for="(v,i) in forms.datas.tips">
							<div v-if="i === 0">添加tips</div>
							<el-input v-model="v.ytl_title" class="form-input" placeholder="请输入子任务内容">
								<template #prepend>
									tip{{i+1}}
								</template>
								<template #suffix>
									<i class="el-input__icon el-icon-delete delete-tip-icon"
										@click="forms.datas.tips.splice(i,1)"></i>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<span class="insert-tip" @click="forms.datas.tips.push({ytl_title:''})">添加tip</span>
						</el-form-item>

						<!-- <el-form-item prop="tips">
							<div>添加tips</div>
							<div style="display: flex;flex-wrap: wrap;">
								<el-checkbox-group v-for="(item, i) in tipsOption" :key="i" v-model="item.tips"
									style="margin-right: 30px;">
									<el-checkbox-button :label="item.id">
										{{item.title}}
									</el-checkbox-button>
									<el-input-number v-model="item.num" :min="1"
										style="width: 130px;margin-left: 10px;margin-top: 5px;height: 20px;" />
								</el-checkbox-group>
							</div>
						</el-form-item> -->
					</el-col>
					<el-col class="right-body" :span="10">
						<el-form-item prop="task_days">
							<div>任务期限（天）</div>
							<el-input v-model="forms.datas.task_days" type="number" class="form-input"
								placeholder="请输入完成任务所需时间(天)">
							</el-input>
						</el-form-item>
						<el-form-item prop="online_date">
							<el-date-picker v-model="forms.datas.online_date" placeholder="请选择预计上线时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item prop="task_type">
							<div>任务类型</div>
							<el-radio-group v-model="forms.datas.task_type">
								<el-radio-button v-for="v in $task_.state.task_manage.task_type" :label="v.id">
									{{v.title}}
								</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item prop="product_ids">
							<div>所属产品</div>
							<el-checkbox-group v-model="forms.datas.product_ids" @change="getWorkModule">
								<el-checkbox-button v-for="v in $task_.state.task_manage.product_list" :label="v.id">
									{{v.title}}
								</el-checkbox-button>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item prop="work_module_ids">
							<div>所属板块</div>
							<el-checkbox-group v-model="forms.datas.work_module_ids">
								<el-checkbox-button v-for="v in $task_.state.task_manage.work_module" :label="v.id">
									{{v.title}}
								</el-checkbox-button>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item prop="team_ids">
							<div>指派团队</div>
							<el-checkbox-group v-model="forms.datas.team_ids">
								<el-checkbox-button v-for="v in $task_.state.task_manage.team_list" :label="v.id">
									{{v.title}}
								</el-checkbox-button>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item prop="urgency_num">
							<div>紧急程度</div>
							<span @click="forms.datas.urgency_num = v"
								:class="['urgency_click',forms.datas.urgency_num == v ? 'urgency_'+v : '']"
								v-for="v in $task_.state.task_manage.urgency_list">{{v}}</span>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="submitForm()">确认编辑
					</el-button>
					<el-button @click="pageConf.show = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import TinymceEditor from '@/components/common/TinymceEditor.vue'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'task_manage_detail',
		components: {
			TinymceEditor
		},
		props: ['task_id'],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties
			const tipsOption = ref([]);  // tips
			// 获取岗位信息
			const getDuty = () => {
				// 执行请求命令
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.duty_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							tipsOption.value = respon.Data;
							tipsOption.value.forEach(item => {
								item.num = 0;
								item.tips = [];
							})
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 表单-挂载
			const formRef = ref()

			// 弹出层配置文件
			const pageConf = reactive({
				show: false,
				editor_placeholder: '请输入任务详情',
				submit_btn_isload: false
			})

			// 获取富文本编辑器值
			const editorContent = (value) => {
				forms.datas.content = value
			}

			// 获取业务板块
			const getWorkModule = () => {
				ctx.$task_.commit('setTaskManageToWorkModule', [])
				if (forms.datas.product_ids.length > 0) {
					// 获取板块信息
					ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.module_info.url, {
						product_ids: forms.datas.product_ids
					})
						.then((respon) => {
							if (respon.Code === 200) {
								ctx.$task_.commit('setTaskManageToWorkModule', respon.Data)
							} else {
								ctx.$notify({
									title: '温馨提示',
									message: respon.Message,
									type: 'warning'
								})
							}
						})
				}
			}

			// 数据表单内容
			const forms = reactive({
				datas: {
					task_id: '',
					title: '',
					content: '',
					tips: [],
					task_days: '',
					online_date: '',
					task_type: '',
					product_ids: [],
					work_module_ids: [],
					team_ids: [],
					urgency_num: '',
					method: 'submit'
				},
				rules: {
					title: {
						required: true,
						message: '请输入任务名称',
						trigger: 'blur'
					},
					content: {
						required: true,
						message: '请输入任务详情',
						trigger: 'blur'
					},
					task_days: {
						required: true,
						message: '请输入任务完成天数',
						trigger: 'blur'
					},
					online_date: {
						type: 'date',
						required: true,
						message: '请选择预计上线时间',
						trigger: 'change'
					},
					task_type: {
						required: true,
						message: '请选择任务类型',
						trigger: 'blur'
					},
					product_ids: {
						type: 'array',
						required: true,
						message: '请选择所属产品',
						trigger: 'blur'
					},
					work_module_ids: {
						type: 'array',
						required: true,
						message: '请选择所属板块',
						trigger: 'blur'
					},
					team_ids: {
						type: 'array',
						required: true,
						message: '请选择指派团队',
						trigger: 'blur'
					},
					urgency_num: {
						required: true,
						message: '请选择紧急程度',
						trigger: 'blur'
					}
				}
			})

			// 显示页面
			const openPage = (task_id) => {
				getDuty();
				// 通过任务ID获取待编辑的数据信息
				ctx.$request_.post(ctx.$api_.state.Task.TaskManage.edit.url, {
					task_id: task_id,
					method: 'search'
				})
					.then((respon) => {
						if (respon.Code === 200) {
							//修改表单数据
							forms.datas.task_id = respon.Data.task_id
							forms.datas.title = respon.Data.title
							forms.datas.content = respon.Data.content
							forms.datas.tips = respon.Data.taskList
							forms.datas.task_days = respon.Data.task_days
							forms.datas.online_date = respon.Data.online_date
							forms.datas.task_type = respon.Data.task_type
							forms.datas.product_ids = respon.Data.product_ids
							forms.datas.work_module_ids = respon.Data.work_module_ids
							forms.datas.team_ids = respon.Data.team_ids
							forms.datas.urgency_num = respon.Data.urgency_num

							// 重置配置信息
							getWorkModule()
							pageConf.show = true
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 提交任务数据
			const submitForm = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 处理选择岗位
						tipsOption.value.forEach(item => {
							if (item.tips.length > 0) {
								forms.datas.tips.push({
									id: item.id,
									num: item.num,
								})
							}
						})
						if (forms.datas.tips.length == 0) {
							ElMessage.error("请选择岗位");
							return false;
						}
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.edit.url, forms
							.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重置配置信息
									pageConf.show = false

									// 抛出
									context.emit('submit_suc')
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}

			return {
				pageConf,
				formRef,
				getWorkModule,
				editorContent,
				forms,
				openPage,
				getDuty,
				tipsOption,
				submitForm
			}
		}
	})
</script>
<style lang="less">
	#task_manage_add {
		.el-dialog {
			margin-top: 3vh !important;

			.el-dialog__body {
				padding: 0 1.1vw;

				.task-body {
					height: 39vw;

					.left-body {
						height: 39vw;
						overflow-y: auto;
						border-right: 1px solid #E6E6E6;

						.insert-tip {
							display: block;
							text-align: center;
							border: 3px dotted #0d84ff;
							color: #0d84ff;
							cursor: pointer;
						}

						.delete-tip-icon {
							cursor: pointer;
						}
					}

					.right-body {
						height: 39vw;
						overflow-y: auto;

						.el-date-editor {
							width: 100%;
						}

						.el-checkbox-button,
						.el-radio-button {
							margin: 0.5vw 0.5vw 0 0;

							.el-checkbox-button__inner,
							.el-radio-button__inner {
								border: 1px solid #dcdfe6 !important;
								border-radius: 0.2vw !important;
								-webkit-box-shadow: none !important;
								box-shadow: none !important;
							}
						}
					}
				}
			}
		}
	}
</style>