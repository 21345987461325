<template>
	<div id="task_manage">
		<el-row>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.product_id" clearable filterable placeholder="全部产品"
					@change="searchTaskList(1)">
					<el-option v-for="item in $task_.state.task_manage.product_list" :label="item.title"
						:value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.team_id" clearable filterable placeholder="全部团队"
					@change="searchTaskList(1)">
					<el-option v-for="item in $task_.state.task_manage.team_list" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.type" clearable filterable placeholder="全部类型"
					@change="searchTaskList(1)">
					<el-option v-for="item in $task_.state.task_manage.task_type" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.status" clearable filterable placeholder="全部状态"
					@change="searchTaskList(1)">
					<el-option v-for="item in tableInfo.conf.status_ids" :label="item.title" :value="item.value">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="1" :offset="2">
				<el-input placeholder="ID" clearable v-model="tableInfo.conf.task_id" @keyup.enter="searchTaskList(2)"
					@clear="searchTaskList(2)">
				</el-input>
			</el-col>
			<el-col :span="5">
				<el-input placeholder="任务名称、创建人姓名或英文简称" clearable v-model="tableInfo.conf.keywords"
					@keyup.enter="searchTaskList(2)" @clear="searchTaskList(2)">
					<template #append>
						<i class="iconfont icon-edit" @click="searchTaskList(2)">&#xe6ab;</i>
					</template>
				</el-input>
			</el-col>
		</el-row>
		<div class="insert">
			<el-button size="medium" icon="el-icon-plus" type="primary" @click="openInsertPage"
				v-if="$pubFun_.verify_permiss($api_.state.Task.TaskManage.add.token,$menu_.state.roleNode)">新建任务
			</el-button>
		</div>
		<div class="table">
			<CusTable ref="cusTable" title="任务管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" @editIcon="openEditPage" @chosePage="tableChosePage"
				@setPageSize="tablePageSize" @showTaskDetail="showTaskDetail" @showTaskProgress="showTaskProgress"
				@tableSort="tableSort" :ispaging="true">
			</CusTable>
		</div>
		<div class="dialog-page">
			<AddTask ref="insertRef" @submit_suc='loadTaskList'></AddTask>
			<EditTask ref="editRef" @submit_suc='loadTaskList'></EditTask>
			<ShowTask ref="showRef" :is_manage_page="true" @edit_task="openEditPage" @load_table='loadTaskList'>
			</ShowTask>
			<ShowProcess ref="processRef" :is_manage_page="true" @load_table='loadTaskList'></ShowProcess>
		</div>
	</div>
</template>

<script type="text/javascript">
	import tableColumns from '@/assets/json/task/table/task-manage-cloumns.json'
	import CusTable from '@/components/common/CusTable.vue'
	import AddTask from '@/components/task/TaskToAdd.vue'
	import EditTask from '@/components/task/TaskToEdit.vue'
	import ShowTask from '@/components/task/TaskToDetail.vue'
	import ShowProcess from '@/components/task/TaskToProcess.vue'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'task_manage',
		components: {
			CusTable,
			AddTask,
			EditTask,
			ShowTask,
			ShowProcess
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取任务类型
			const getTaskType = () => {
				// 获取任务类型
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.task_type_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							ctx.$task_.commit('setTaskManageToTaskType', respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 获取产品信息
			const getProduct = () => {
				// 获取产品信息
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.product_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							ctx.$task_.commit('setTaskManageToProductList', respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 获取团队信息
			const getTeam = () => {
				// 执行请求命令
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.team_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							ctx.$task_.commit('setTaskManageToTeamList', respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})

			}

			// 列表信息
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'TaskManage'),
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 15,
					dataTotal: 0,
					sigleHide: false,
					task_id: ctx.$route.query.task_id ? ctx.$route.query.task_id : '',
					keywords: '',
					product_id: '',
					team_id: '',
					sort_field: '',
					sort_order: '',
					type: '',
					status: '',
					emptyText: '',
					status_ids: [{
						title: "待激活",
						value: 1
					}, {
						title: "进行中",
						value: 2
					}, {
						title: "已完成",
						value: 3
					}, {
						title: "已发布",
						value: 5
					}, {
						title: "已撤销",
						value: 4
					}]
				}
			})

			// 顶部搜索栏
			const searchTaskList = (type) => {
				if (type === 1) {
					//select筛选，清空任务ID、关键字查询
					tableInfo.conf.task_id = ''
					tableInfo.conf.keywords = ''
				} else if (type === 2) {
					//关键字查询，清空select筛选
					tableInfo.conf.product_id = ''
					tableInfo.conf.team_id = ''
					tableInfo.conf.type = ''
					tableInfo.conf.status = ''
				}

				// 重置分页参数
				tableInfo.conf.curPage = 1

				// 执行搜索请求
				loadTaskList()
			}

			// 获取任务列表
			const loadTaskList = (isOpenTaskDetail = false) => {
				// 重置参数
				tableInfo.list = []
				tableInfo.conf.loadStatus = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.TaskManage.list.url, {
						page: tableInfo.conf.curPage,
						limit: tableInfo.conf.pageLimit,
						task_id: tableInfo.conf.task_id,
						product_id: tableInfo.conf.product_id,
						team_id: tableInfo.conf.team_id,
						type: tableInfo.conf.type,
						status: tableInfo.conf.status,
						keywords: tableInfo.conf.keywords,
						field: tableInfo.conf.sort_field,
						sort: tableInfo.conf.sort_order
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 存放任务数据
							tableInfo.list = respon.Data.datas
							tableInfo.conf.dataTotal = respon.Data.Count

							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = '没有更多的数据了~'

							// 判断是否存在自动打开详情页
							if (isOpenTaskDetail === true && tableInfo.conf.task_id) {
								showTaskDetail(0, tableInfo.conf.task_id)
							}
						} else {
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = respon.Message
						}
					}).catch(error => {
						tableInfo.conf.loadStatus = false
						tableInfo.conf.emptyText = "服务器连接失败，请稍后重试"
					})
			}

			// 监听table排序变化
			const tableSort = (filed, order) => {
				// 重置分页页码为1
				tableInfo.conf.curPage = 1

				// 存放排序字段和方式
				tableInfo.conf.sort_field = filed
				tableInfo.conf.sort_order = order

				// 重新加载table数据
				loadTaskList()
			}

			// 设置table当前页码变化
			const tableChosePage = (curPage) => {
				// 存放当前页面
				tableInfo.conf.curPage = curPage

				// 重新加载table数据
				loadTaskList()
			}

			// 设置table每页页码数
			const tablePageSize = (limit, page) => {
				// 存放页码数
				tableInfo.conf.pageLimit = limit

				// 判断查询当前页  *  条数 > 总条数不进行查询
				if (page === 1 || limit * page <= tableInfo.conf.dataTotal) {
					// 重新加载table数据
					loadTaskList()
				}
			}

			// 新增任务挂载
			const insertRef = ref(null)

			// 打开新建任务界面
			const openInsertPage = () => {
				insertRef.value.openPage()
			}

			// 编辑任务挂载
			const editRef = ref(null)

			// 打开编辑任务界面
			const openEditPage = (index, task_id = 0) => {
				editRef.value.openPage(task_id > 0 ? task_id : tableInfo.list[index].task_id)
			}

			// 任务详情挂载
			const showRef = ref(null)

			// 打开任务详情界面
			const showTaskDetail = (index, task_id = 0) => {
				showRef.value.openPage(task_id > 0 ? task_id : tableInfo.list[index].task_id)
			}

			// 任务进度挂载
			const processRef = ref(null)

			// 打开任务进度界面
			const showTaskProgress = (index) => {
				processRef.value.openPage(tableInfo.list[index].task_id)
			}

			/*初次自动加载*/
			getTaskType()
			getProduct()
			getTeam()
			loadTaskList(true)
			/*初次自动加载*/

			// 判断存在参数信息，有则清除掉
			if (JSON.stringify(ctx.$route.query) != '{}') {
				window.location.hash = ctx.$route.path
			}

			return {
				tableInfo,
				loadTaskList,
				tableSort,
				tableChosePage,
				tablePageSize,
				searchTaskList,
				insertRef,
				openInsertPage,
				editRef,
				openEditPage,
				showRef,
				showTaskDetail,
				processRef,
				showTaskProgress
			}
		}
	})
</script>

<style lang="less">
	#task_manage {
		.insert {
			margin-top: 1vw;
		}
	}
</style>
