<template>
	<div id="tinymce_editor">
		<div class="tinymce-editor">
			<Editor id="tinymce" v-model="editorContent.value" :init="editorInit"></Editor>
		</div>
	</div>
</template>

<script>
	import tinymce from 'tinymce/tinymce'
	import Editor from '@tinymce/tinymce-vue'
	import 'tinymce/themes/silver/theme'
	import 'tinymce/icons/default'
	import 'tinymce/plugins/image'
	import 'tinymce/plugins/link'
	import 'tinymce/plugins/code'
	import 'tinymce/plugins/table'
	import 'tinymce/plugins/lists'
	import 'tinymce/plugins/advlist'
	import 'tinymce/plugins/fullscreen'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'tinymce_editor',
		components: {
			Editor
		},
		props: ['content', 'placeholder'],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			const editorInit = ref({
				language_url: '/static/tinymce/lang/zh_CN.js',
				language: 'zh_CN',
				skin_url: '/static/tinymce/skins/ui/oxide',
				height: 350,
				menubar: '',
				placeholder: props.placeholder,
				plugins: 'link lists advlist image code table fullscreen',
				toolbar: [
					'bold italic underline strikethrough | fontsizeselect | forecolor backcolor| alignleft aligncenter alignright alignjustify ',
					'outdent indent blockquote| bullist numlist table | undo redo| link unlink image code | fullscreen removeformat'
				],
				branding: false,
				images_upload_handler: function(blobInfo, succFun, failFun) {
					var xhr, formData;
					var file = blobInfo.blob();

					// 获取服务器上传凭证
					ctx.$request_nl_.post(ctx.$api_.state.Home.BaseInfo.service_uptoken.url)
						.then((respon) => {
							if (respon.Code === 200) {
								formData = new FormData();
								formData.append('file', file)
								formData.append('token', respon.Data.service_uptoken)

								// 执行上传操作
								ctx.$request_nl_.post(ctx.$api_.state.Home.BaseInfo.service_upload
										.url, formData)
									.then((res) => {
										if (res.Code === 200) {
											succFun(res.Data.filepath)
										} else {
											failFun(res.Message);
										}
									}).catch(error => {
										failFun('网络连接失败！');
									})
							} else {
								failFun(respon.Message);
							}
						}).catch(error => {
							failFun('网络连接失败！');
						})
				},
				file_picker_types: 'file',
				file_picker_callback: function(callback, value, meta) {
					//模拟出一个input用于添加本地文件
					var input = document.createElement('input');
					input.setAttribute('type', 'file');
					input.setAttribute('accept', meta.filetype);
					input.click();

					input.onchange = function() {
						var file = this.files[0];
						var xhr, formData;
						
						// 获取服务器上传凭证
						ctx.$request_nl_.post(ctx.$api_.state.Home.BaseInfo.service_uptoken.url)
							.then((respon) => {
								if (respon.Code === 200) {
									formData = new FormData();
									formData.append('file', file)
									formData.append('token', respon.Data.service_uptoken)
						
									// 执行上传操作
									ctx.$request_nl_.post(ctx.$api_.state.Home.BaseInfo.service_upload
											.url, formData)
										.then((res) => {
											if (res.Code === 200) {
												callback(res.Data.filepath, {text: file.name});
											} else {
												ctx.$notify({
													title: '温馨提示',
													message: res.Message,
													type: 'warning'
												})
											}
										}).catch(error => {
											ctx.$notify({
												title: '温馨提示',
												message: '网络连接失败!',
												type: 'warning'
											})
										})
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							}).catch(error => {
								ctx.$notify({
									title: '温馨提示',
									message: '网络连接失败！',
									type: 'warning'
								})
							})
					}
				}
			})

			const editorContent = reactive({
				value: props.content
			})

			onMounted(() => {
				tinymce.init(editorInit)
			})

			watch(editorContent, (obj) => {
				context.emit('editorContent', obj.value)
			})

			return {
				editorContent,
				editorInit
			}
		}
	})
</script>
<style lang="less">
	#tinymce_editor {
		.tox-statusbar__branding {
			display: none;
		}
	}

	.tox-tinymce-aux {
		z-index: 5000 !important;
	}
</style>
